import { createApp } from 'vue'
import App from './App.vue'
// router
import router from './router'
// css
import './assets/css/reset.css'
// import './assets/css/default_22home.css'
import './assets/css/head_01.css'
import './assets/css/xlcd.css'
import './assets/css/zxbox.css'
// import 'amfe-flexible' //自适应有问题
// Shopmanagement
// import './assets/css/Shopmanagement/tky.css'
// import './assets/css/Shopmanagement/default_bluehome.css'
// import './assets/css/Shopmanagement/product.css'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// member
// import '@/assets/css/member/default_bluehome.css'
const app = createApp(App)
app.use(router)
app.mount('#app')
