
import { defineComponent } from 'vue'

import Head from '@/components/head.vue'
import Rotograph from '@/components/rotograph/rotograph.vue'
import Buttom from '@/views/buttom/buttom.vue'

// import cs from './components/cs.vue'

export default defineComponent({
  name: 'App',
  components: {
    Head,
    Rotograph,
    Buttom
  }
})
