import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  createWebHashHistory
} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    // 重定向
    path: '/',
    redirect: '/home'
  },
  {
    // 首页
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home/Home.vue')
  },
  {
    // 电务管理
    path: '/Shopmanagement',
    name: 'Shopmanagement',
    component: () => import('@/views/Shopmanagement/Shopmanagement.vue')
  },
  {
    // 会员系统
    path: '/member',
    name: 'member',
    component: () => import('@/views/member/member.vue')
  },
  {
    // 收银系统
    path: '/cashier',
    name: 'cashier',
    component: () => import('@/views/cashier/cashier.vue')
  },
  {
    //私域运营
    path: '/PrivateDomain',
    name: 'PrivateDomain',
    component: () => import('@/views/Private-domain/PrivateDomain.vue')
  },
  {
    // 和商达小程序
    path: '/hsd',
    name: 'hsd',
    component: () => import('@/views/hsd/hsd.vue')
  },
  {
    // 咨询动态
    path: '/consultation',
    name: 'consultation',
    component: () => import('@/views/consultation/consultation.vue')
  },
  {
    // 关于我们
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/AboutUs/AboutUs.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
