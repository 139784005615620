<template>
  <div class="bottom_logo">
    <div class="bottom_logo_box">
      <img
        src="@/cdn.dyysoft.net/dyysoft_net/huiyapp02.jpg"
        style="margin-right: 312px; margin-left: 10px"
      />

      <div style="float: left; width: 145px; margin-right: 14px">
        <h5>解决方案</h5>
        <a href="product.html" class="bot">智能店务管理系统</a><br />
        <a href="tky.html" class="bot">全渠道营销拓客方案</a><br />

        <a href="xcx.html" class="bot">美业专属小程序商城</a><br />

        <a href="myAPP.html" class="bot">手机移动管店APP</a>
      </div>
      <div style="float: left; width: 120px">
        <h5>快速导航</h5>

        <a href="jjfa.html" class="bot"><span>解决方案</span></a
        ><br />
        <a href="hygl.html" class="bot">会员系统</a><br />
        <a href="syjz.html" class="bot">收银软件</a><br />
        <a href="syscrm.html" class="bot">私域运营scrm</a><br />
      </div>
      <div style="float: left; padding-top: 20px">
        <h5></h5>
        <a href="bbjs.html" class="bot" rel="nofollow">版本&功能</a><br />
        <a href="gsjj.html" class="bot" rel="nofollow">关于我们</a><br />
        <a href="news.html" class="bot" rel="nofollow">新闻中心</a><br />
        <a href="sitemap.html" class="bot">网站地图</a>
      </div>

      <div class="tit" style="margin-left: 78px">
        <h5>广东和商达网络科技有限公司<br /></h5>

        地址： 中山市火炬开发区博爱七路88号聚豪园峰晴苑10幢702房<br />
        售前咨询：17606622666<br />
        售后热线：17606622666<br />
        备案号:<a
          href="https://beian.miit.gov.cn"
          target="_blank"
          rel="nofollow"
          >粤ICP备2022120346号
        </a>
      </div>
    </div>

    <div>
      <!--友情链接-->
      <div class="box_bottom">
        <div class="bottom0">
          <p>
            <span></span>友情链接 links
            <a href="link/apply.html" class="lin">申请链接</a>
          </p>

          <a
            href="https://www.xiziwang.net/zhaoxing/"
            class="lin"
            target="_blank"
            >头发造型</a
          ><font color="#ccc"> |</font>
          <a href="http://www.facemeeting.cn/" class="lin" target="_blank"
            >视频会议系统</a
          ><font color="#ccc"> |</font>
          <a href="http://www.pltsmr.com/" class="lin" target="_blank"
            >美容院加盟</a
          ><font color="#ccc"> |</font>
          <a href="http://www.dabaicai.org/" class="lin" target="_blank"
            >大白菜u盘装系统 </a
          ><font color="#ccc"> |</font>
          <a href="http://www.10oa.com/" class="lin" target="_blank">OA系统</a
          ><font color="#ccc"> |</font>
          <a href="https://zhsmjxc.com/" class="lin" target="_blank"
            >进销存软件</a
          ><font color="#ccc"> |</font>
          <a href="http://www.soft78.com/" class="lin" target="_blank">
            软件产品网</a
          ><font color="#ccc"> |</font>
          <a href="http://meirong.qudao.com/" class="lin" target="_blank"
            >美容加盟</a
          ><font color="#ccc"> |</font>
          <a href="http://www.ufidaoa.com/" class="lin" target="_blank"
            >OA办公系统</a
          ><font color="#ccc"> |</font>
          <a href="http://www.djlsoft.net/" class="lin" target="_blank"
            >服装进销存软件</a
          ><font color="#ccc"> |</font>
          <a href="http://chanhouhuifu.jiameng.com/" class="lin" target="_blank"
            >产后恢复加盟</a
          ><font color="#ccc"> |</font>
          <a href="http://www.ssfei.com/" class="lin" target="_blank"
            >成都化妆学校</a
          ><font color="#ccc"> |</font>
          <a href="http://www.zoneidc.com/" class="lin" target="_blank"
            >香港云服务器</a
          ><font color="#ccc"> |</font>
          <a href="http://www.yunbaokj.com/" class="lin" target="_blank"
            >直播系统</a
          ><font color="#ccc"> |</font>
          <a href="http://www.happicall.com/" class="lin" target="_blank"
            >呼叫中心系统</a
          ><font color="#ccc"> |</font>
          <a href="http://www.weihaobang.com/" class="lin" target="_blank"
            >带参二维码</a
          ><font color="#ccc"> |</font>
          <a href="http://www.jiayn.cn/" class="lin" target="_blank"
            >人脸识别系统</a
          ><font color="#ccc"> |</font>
          <a href="http://www.nakevip.com/" class="lin" target="_blank"
            >会员管理软件</a
          ><font color="#ccc"> |</font>
          <a href="http://www.hl95.com/" class="lin" target="_blank"
            >呼叫中心系统</a
          ><font color="#ccc"> |</font>
          <a href="http://meiwutong.com/" class="lin" target="_blank"
            >美容院管理系统</a
          ><font color="#ccc"> |</font>
        </div>
      </div>
      <!--友情链接结束-->
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
a.bot:link {
  color: #fff;
  font-size: 13px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin-bottom: 5px;
  font-weight: 100;
}

a.bot:visited {
  color: #fff;
  font-size: 13px;
  font-weight: 100;
}
.bottom {
  width: 960px;
  margin: 0 auto;
  padding-top: 0px;
  height: 73px;
  line-height: 20px;
  text-align: left;
  clear: both;
}

.bottom span {
  float: right;
  padding-top: 0px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin-left: 15px;
}

.bottom span img {
  margin-top: 10px;
}
/* *bottom*/
.bottom_logo {
  background: #333333;
  overflow: hidden;
  width: 100% !important;
}

.bottom_logo:before {
  content: '';
  clear: both;
  overflow: hidden;
}

.bottom_logobottom {
  background: #e5e5e5;
  border-top: #c6c6c6 solid 1px;
  clear: both;
}

.bottom_logo_box {
  width: 1230px;
  margin: 20px auto;
  color: #505050;
  overflow: hidden;
  padding: 10px 0;

  border-bottom: 2px solid #454545;
  padding-top: 40px;
  padding-bottom: 20px;
  line-height: 200%;
}
.bottom_logo_box .buttom_logo_vx {
  background-color: red;
}
.bottom_logo_box span {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

.bottom_logo_box h5 {
  font-size: 16px;
  padding-bottom: 2px;
  margin: 10px 0;
  color: #fff;
}

.bottom_logo_box img {
  float: left;
  visibility: hidden;
  margin-top: 0px;
}

.bottom_logo_box .tit {
  float: left;
  width: 362px;
  margin-left: 85px;
  color: #ffffff;
  line-height: 250%;
  font-size: 14px;
}

.bottom_logo_box .tit h5 {
  line-height: 200%;
}

.bottom_logo_box .tit span {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin: 0;
  float: left;
  margin-left: 0px;
}

.bottom_logo_box .tit span img {
  margin: 0;
}

.bottom_logo_box .tit a {
  color: #ffffff;
}
/*bottom*/
#close_c {
  position: absolute;
  left: -20px;
  top: 20px;
}

.box_bottom {
  width: 100%;
  background: #333333;
  padding: 10px 0;
  padding-bottom: 30px;
}

.bottom0 p {
  font-family: '微软雅黑';
  color: #ffffff;
  font-size: 15px;
  padding-bottom: 15px;
}

.bottom0 p span {
  float: right;
}
a.lin:link {
  color: #ffffff;
  text-decoration: none;
  font-family: 微软雅黑;
}
.bottom0 {
  width: 1200px;
  margin: 0px auto;
  line-height: 20px;
  padding: 5px;
  clear: both;
}
</style>
