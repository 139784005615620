<template>
  <div class="headerher" :class="{ hfix: scrollTop > 330 }">
    <div class="header" :class="{ hfixc: scrollTop > 330 }">
      <a href="#" rel="nofollow">
        <li class="right01" :class="{ right02: scrollTop > 330 }">
          申请免费试用
        </li>
      </a>
      <a href="index.html"
        ><div class="logoimg">
          <h1>
            <img
              src="../assets/img/1/80f402f606d0f4282e592ec40b8b710.jpg"
              width="120"
              height="60"
              title="美盈易官网"
              alt="美盈易官网"
            />
          </h1></div
      ></a>
      <div class="logoright" :class="{ logoright01: scrollTop > 330 }">
        <div class="img"></div>
        <div class="logorighttext">
          和商达
          <div class="sub">店务·会员·私域</div>
        </div>
      </div>
      <li class="hover"><router-link to="./home">首页</router-link></li>

      <li class="cpjs">
        <router-link to="./Shopmanagement"
          >店务管理<i class="fa fa-angle-down"></i
        ></router-link>
        <div class="cpjs_down">
          <div class="cpjs_down_box">
            <ul>
              <div class="cpjs_down_box-item">
                <h3>
                  <div class="more"><a href="#">了解详情</a></div>
                  <a href="#">收银管理</a>
                </h3>
                开单收银扫码支付，自动记账不必算账
              </div>
              <div class="cpjs_down_box-item">
                <h3>
                  <div class="more"><a href="#">了解详情</a></div>
                  <a href="#">会员管理</a>
                </h3>
                系统沉淀会员资源，促活\留存\个性营销
              </div>
              <div class="cpjs_down_box-item">
                <h3>
                  <div class="more"><a href="#">了解详情</a></div>
                  <a href="#">预约/排班</a>
                </h3>
                在线预约自动排班，服务满意工作有序
              </div>
              <div class="cpjs_down_box-item">
                <h3>
                  <div class="more"><a href="yggl.html">了解详情</a></div>
                  <a href="#">员工管理</a>
                </h3>
                打造内驱服务团队，效率提升业绩倍增
              </div>
            </ul>
            <ul>
              <div class="cpjs_down_box-item">
                <h3>
                  <div class="more"><a href="#">了解详情</a></div>
                  <a href="#">商品库存</a>
                </h3>
                疗程套餐灵活设置，库存补给智能提醒
              </div>
              <div class="cpjs_down_box-item">
                <h3>
                  <div class="more"><a href="#">了解详情</a></div>
                  <a href="#">连锁管理</a>
                </h3>
                多门店统一管理，标准化快速扩张品牌
              </div>
              <div class="cpjs_down_box-item">
                <h3>
                  <div class="more"><a href="#">了解详情</a></div>
                  <a href="#">报表管理</a>
                </h3>
                多维数据统计分析，支持决策避免风险
              </div>
              <div class="cpjs_down_box-item">
                <h3>
                  <div class="more"><a href="#">了解详情</a></div>
                  <a href="#">美业小程序</a>
                </h3>
                小程序赋能新美业，流量变现高效转化
              </div>
            </ul>
          </div>
        </div>
      </li>
      <li class="jjfa"><router-link to="/member">会员系统</router-link></li>
      <li class="jjfa"><router-link to="/cashier">收银软件</router-link></li>

      <li class="jjfa">
        <router-link to="/PrivateDomain">私域运营scrm</router-link>
      </li>

      <li class="jjfa"><router-link to="/hsd">和商达小程序</router-link></li>

      <li class="news">
        <router-link to="/consultation">咨询动态</router-link>
      </li>
      <li class="about"><router-link to="/aboutUs">关于我们</router-link></li>
    </div>
  </div>
</template>

<script scoped>
import { ref } from 'vue'
export default {
  components: {},
  // mounted() {},
  setup() {
    const scrollTop = ref(0)
    window.addEventListener('scroll', (e) => {
      scrollTop.value = document.documentElement.scrollTop
      // console.log(scrollTop.value)
    })

    return {
      scrollTop
    }
  }
}
</script>

<style scoped>
/*首页主菜单*/
.headerher {
  clear: both;
  position: fixed;
  z-index: 100;
  left: 0px;
  top: 0px;
  width: 100%;
  background: transparent;
}

.hfix {
  background: #ffffff !important;
  -webkit-box-shadow: 0 0 8px rgba(134, 134, 134, 0.3);
  -moz-box-shadow: 0 0 8px rgba(134, 134, 134, 0.3);
  box-shadow: 0 0 8px rgba(134, 134, 134, 0.3);
  height: 75px;
}

.header {
  height: 75px;
  line-height: 75px;
  width: 1206px;
  margin: 0 auto;
}

.header li {
  padding: 0px 7px;
  vertical-align: top;
  display: inline-block;
  position: relative;
  z-index: 10;
  -webkit-transition: all ease 300ms;
  -moz-transition: all ease 300ms;
  -ms-transition: all ease 300ms;
  -o-transition: all ease 300ms;
  transition: all ease 300ms;
  left: 50px;
}

.header li a {
  font-size: 15px;
  color: #ffffff;
  padding: 0px 3px;
  height: 78px;
  text-align: center;
  color: rgb(255, 255, 255);
  line-height: 78px;
  overflow: hidden;
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-transition: all ease 300ms;
  -moz-transition: all ease 300ms;
  -ms-transition: all ease 300ms;
  -o-transition: all ease 300ms;
  transition: all ease 300ms;
}
.header li a {
  text-decoration: none;
}
.header li a:before {
  background: rgb(255, 255, 255);
  left: 0px;
  width: 0px;
  height: 2px;
  bottom: 13px;

  position: absolute;
  content: '\20';
  -webkit-transition: all ease 300ms;
  -moz-transition: all ease 300ms;
  -ms-transition: all ease 300ms;
  -o-transition: all ease 300ms;
  transition: all ease 300ms;
}
/* .header li a:hover:before {
  display: block;
} */
.header .cdmrmf ul li span a:hover {
  padding: 0 10px 0 16px;
  position: relative;
  display: inline-block;
  color: #ffffff !important;
  z-index: 5;
}

.hfixc .cdmrmf ul li span a:hover {
  padding: 0 10px 0 16px;
  position: relative;
  display: inline-block;
  color: #ef3171 !important;
  z-index: 5;
}

.header .jt_box {
  background: url(../images/mrmf/jt/x_jtaa.png);
  display: inline-block;
  vertical-align: middle;
  width: 7px;
  height: 4px;
  margin-left: 5px;
}

.header li:hover .jt_box {
  background: url(../images/mrmf/jt/s_jtaa.png);
}

.hfixc .jt_box {
  background: url(../images/mrmf/jt/x_jt.png);
  display: inline-block;
  vertical-align: middle;
  width: 7px;
  height: 4px;
  margin-left: 2px;
}

.hfixc li:hover .jt_box {
  background: url(../images/mrmf/jt/s_jt.png);
}

.header li a:hover {
  color: #ffffff !important;
}
.hfixc li a:hover {
  color: #ff7ba7 !important;
}
.header li:hover a::before {
  width: 100%;
}

.header li.hover a::before {
  width: 100%;
}

/* .header li.hover a {
}

.header li:hover a {
} */

.hfixc {
  height: 75px;
  line-height: 75px;
  width: 1206px;
  margin: 0 auto;
}

.hfixc li {
  padding: 0px 7px;
  vertical-align: top;
  display: inline-block;
  position: relative;
  z-index: 10;
  -webkit-transition: all ease 300ms;
  -moz-transition: all ease 300ms;
  -ms-transition: all ease 300ms;
  -o-transition: all ease 300ms;
  transition: all ease 300ms;
  left: 50px;
}

.hfixc li a {
  font-size: 15px;
  color: #333333;
  padding: 0px 3px;
  height: 78px;
  text-align: center;
  line-height: 78px;
  overflow: hidden;
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-transition: all ease 300ms;
  -moz-transition: all ease 300ms;
  -ms-transition: all ease 300ms;
  -o-transition: all ease 300ms;
  transition: all ease 300ms;
}

.hfixc li a::before {
  background: rgb(521, 82, 139);
  left: 0px;
  width: 0px;
  height: 2px;
  bottom: 13px;
  display: block;
  position: absolute;
  content: '\20';
  -webkit-transition: all ease 300ms;
  -moz-transition: all ease 300ms;
  -ms-transition: all ease 300ms;
  -o-transition: all ease 300ms;
  transition: all ease 300ms;
}

.hfixc li:hover a::before {
  width: 100%;
}

.hfixc li.hover a::before {
  width: 100%;
}

.hfixc li.hover a {
  color: #ef3171;
}

.hfixc li:hover {
  font-size: 19px;
  color: #ffffff;
}

.hfixc .right01 {
  padding: 0 10px;
  text-align: center;
  float: right;
  height: 44px;
  line-height: 44px;
  width: 153px;
  color: #fff;
  font-size: 16px;
  margin-top: 15px;
  margin-left: 30px;
  border: 0px solid #ffffff;
  border-radius: 8px;
  position: relative;
  left: 0px;
}

/* .hfixc .right01:hover {
  padding: 0 10px;
  text-align: center;
  float: right;
  height: 44px;
  line-height: 44px;
  width: 153px;
  color: #12afc8;
  background-color: #ffffff;
  font-size: 16px;
  margin-top: 15px;
  margin-left: 30px;
  border: 0px solid #ffffff;
  border-radius: 8px;
  position: relative;
  left: 0px;
} */

.hfixc .right02 {
  padding: 0 10px;
  text-align: center;
  /* background: url(@/images/images/images2018/mfsy.html); */
  /* background: #fb538b; */
  float: right;
  height: 44px;
  line-height: 44px;
  width: 153px;
  color: #ffffff;
  font-size: 16px;
  margin-top: 15px;
  margin-left: 30px;
  border: 0px solid #ffffff;
  position: relative;
  left: 0px;
}

.hfixc .right02:hover {
  padding: 0 10px;
  text-align: center;
  background-color: #ffffff;
  /* background: url(../../images/images/images2018/mfsy.html); */
  float: right;
  height: 44px;
  line-height: 44px;
  width: 153px;
  color: #12afc8;
  font-size: 16px;
  margin-top: 15px;
  margin-left: 30px;
  border: 0px solid #ffffff;
  position: relative;
  left: 0px;
}

.header .right01 {
  padding: 0 10px;
  text-align: center;
  float: right;
  height: 44px;
  line-height: 44px;
  width: 153px;
  color: #ffffff;
  background: rgba(255, 255, 255, 1);
  font-size: 16px;
  margin-top: 15px;
  margin-left: 30px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  position: relative;
  left: 0px;
  color: #ef3171;
}

.header .right01:hover {
  padding: 0 10px;
  text-align: center;
  float: right;
  height: 42px;
  line-height: 42px;
  width: 151px;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  font-size: 16px;
  margin-top: 15px;
  margin-left: 30px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  position: relative;
  left: 0px;
}

.header .right02 {
  padding: 0 10px;
  text-align: center;
  background: -webkit-linear-gradient(left, #fb538b, #ff8b9f);
  /* background: -o-linear-gradient(left, #fb538b, #ff8b9f);
  background: -moz-linear-gradient(left, #fb538b, #ff8b9f); */
  /* background: linear-gradient(left, #fb538b, #ff8b9f);  */
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  float: right;
  height: 44px;
  line-height: 44px;
  width: 153px;
  color: #ffffff;
  font-size: 16px;
  margin-top: 15px;
  margin-left: 30px;
  border: 0px solid #ffffff;
  position: relative;
  left: 0px;
}

.header .right02:hover {
  padding: 0 10px;
  text-align: center;
  background: -webkit-linear-gradient(left, #73a5f1, #2b3481);
  /* background: -o-linear-gradient(left, #fb538b, #ff8b9f);
  background: -moz-linear-gradient(left, #fb538b, #ff8b9f);
  background: linear-gradient(left, #fb538b, #ff8b9f); */
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  float: right;
  height: 44px;
  line-height: 44px;
  width: 153px;
  color: #ffffff;
  font-size: 16px;
  margin-top: 15px;
  margin-left: 30px;
  border: 0px solid #ffffff;
  position: relative;
  left: 0px;
}

.logoimg {
  width: 125px;
  height: 57px;
  /* background: url(../assets/img/1/80f402f606d0f4282e592ec40b8b710.jpg); */
  margin-top: 2px;
  float: left;
}

.logoimg01 {
  width: 125px;
  height: 57px;
  background: url(../cdn.dyysoft.net/dyysoft_net/logo.jpg);
  margin-top: 10px;
  float: left;
}

.logoimg01 img,
.logoimg img {
  /* opacity: 0; */
  filter: alpha(opacity=0);
}
a.sqmfsymrmf:link {
  background: -webkit-linear-gradient(left, #fb538b, #ff8b9f);
  background: -o-linear-gradient(left, #fb538b, #ff8b9f);
  background: -moz-linear-gradient(left, #fb538b, #ff8b9f);
  background: linear-gradient(left, #fb538b, #ff8b9f);
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  font-family: '微软雅黑';
  border-radius: 5px;
  color: #fff;
  font-size: 17px;
  /* float: right; */
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 110px;
  text-align: center;
  width: 150px;
  height: 45px;
  line-height: 45px;
  margin: 5px 5px 0px 0px;
  font-weight: 100;
}

a.sqmfsymrmf:visited {
  background: -webkit-linear-gradient(left, #fb538b, #ff8b9f);
  background: -o-linear-gradient(left, #fb538b, #ff8b9f);
  background: -moz-linear-gradient(left, #fb538b, #ff8b9f);
  background: linear-gradient(left, #fb538b, #ff8b9f);
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 17px;
  font-weight: 100;
}

a.sqmfsymrmf:hover {
  background: -webkit-linear-gradient(left, #fb538b, #ff8b9f);
  background: -o-linear-gradient(left, #fb538b, #ff8b9f);
  background: -moz-linear-gradient(left, #fb538b, #ff8b9f);
  background: linear-gradient(left, #fb538b, #ff8b9f);
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 17px;
  font-weight: 100;
}

a.sqmfsymrmf:active {
  background: -webkit-linear-gradient(left, #fb538b, #ff8b9f);
  background: -o-linear-gradient(left, #fb538b, #ff8b9f);
  background: -moz-linear-gradient(left, #fb538b, #ff8b9f);
  background: linear-gradient(left, #fb538b, #ff8b9f);
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 17px;
  font-weight: 100;
}
</style>
