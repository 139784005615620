<template>
  <div class="adv_home">
    <div class="focus" id="focus">
      <div id="focus_m" class="focus_m">
        <ul>
          <swiper
            :navigation="true"
            :pagination="true"
            :autoplay="true"
            :loop="true"
            :modules="modules"
            class="mySwiper"
            @swiper="mySwiper"
            @slideChange="onSlideChangeee"
          >
            <swiper-slide>
              <li class="focus_mlibg01">
                <div class="icon"></div>

                <div class="jypx_main_box jypx_main_box_box">
                  <div class="baner_left">
                    <div class="bal01">高效管理店务</div>
                    <div class="bal02">
                      易用的智能美业管理系统，大幅 提升效率69%
                    </div>
                    <div class="bal03">
                      扫码快速收银，微信在线预约，员工提成自动统计
                    </div>
                    <a class="homemfsybut" href="mfsy.html">免费申请试用></a>
                  </div>
                  <div class="baner_right">
                    <img
                      src="@/cdn.dyysoft.net/dyysoft_net/banner/banne01_t.png"
                    />
                  </div>
                </div></li
            ></swiper-slide>
            <swiper-slide>
              <li class="focus_mlibg02">
                <div class="icon"></div>
                <div class="jypx_main_box jypx_main_box_box">
                  <div class="baner_left">
                    <div class="bal01">私域运营SCRM</div>
                    <div class="bal02">
                      私域流量池运营管理，搞定拓、留、锁、升
                    </div>
                    <div class="bal03" style="padding-bottom: 65px">
                      全域引流获客，私域促活转化，社群裂变增长
                    </div>
                    <a class="homemfsybut" href="mfsy.html">免费申请试用></a>
                  </div>
                  <div class="baner_right">
                    <img
                      src="@/cdn.dyysoft.net/dyysoft_net/banner/banne02_t.png"
                    />
                  </div>
                </div></li
            ></swiper-slide>
            <swiper-slide>
              <li class="focus_mlibg03">
                <div class="icon"></div>
                <div class="jypx_main_box jypx_main_box_box">
                  <div class="baner_left">
                    <div class="bal01">美业微信小程序</div>
                    <div class="bal02">
                      帮美容院打通微信能力，轻松获取<br />流量红利
                    </div>
                    <div class="bal03">
                      门店与服务展示，直播引流，连接客户，分享传播
                    </div>
                    <a class="homemfsybut" href="mfsy.html">免费申请试用></a>
                  </div>
                  <div class="baner_right">
                    <img
                      src="@/cdn.dyysoft.net/dyysoft_net/banner/banne03_t.png"
                    />
                  </div>
                </div></li
            ></swiper-slide>
            <swiper-slide>
              <li class="focus_mlibg04">
                <div class="icon"></div>
                <div class="jypx_main_box jypx_main_box_box">
                  <div class="baner_left">
                    <div class="bal01">智能AI经营报表</div>
                    <div class="bal02">
                      多维度经营报表分析，赋能商家 业绩全面提升
                    </div>
                    <div class="bal03">
                      重要报表自动生成，业绩随时看，经营数据全掌握
                    </div>
                    <a class="homemfsybut" href="mfsy.html">免费申请试用></a>
                  </div>
                  <div class="baner_right">
                    <img
                      src="@/cdn.dyysoft.net/dyysoft_net/banner/banne04_t.png"
                    />
                  </div>
                </div></li
            ></swiper-slide>
            <div class="swiper-pagination"></div>
            <!--分页器。如果放置在swiper外面，需要自定义样式。-->
          </swiper>
        </ul>
      </div>
    </div>
  </div>
</template>

<script scoped>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation' //导航模块
import 'swiper/css/pagination' //分页模块
import 'swiper/css/autoplay' //自动播放模块
import 'swiper/css/effect-fade' //淡入淡出
import { reactive, ref } from 'vue'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const mySwiper = ref(null)
    const active = ref(0)
    const onSwiper = (swiper) => {
      console.log(swiper)
    }
    const onSlideChange = (swiper) => {
      // console.log(swiper.pagination.el)
      // console.log(swiper.pagination)
    }

    return {
      // swiper,
      mySwiper,

      modules: [Navigation, Pagination, Autoplay, EffectFade],
      onSwiper,
      onSlideChange
    }
  }
}
</script>

<style>
.swiper {
  height: 100%;
}
/*首页轮播图*/
.switch_item {
  width: 298px;
  height: 100px;
  float: left;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  display: block;
}
.switch_itemright {
  border-right: 0px solid #ffffff;
}
.switch_item .left {
  width: 60px;
  text-align: left;
  float: left;
  padding-top: 25px;
  padding-left: 35px;
}
.switch_item .right {
  width: 188px;
  float: left;
  text-align: left;
  padding-top: 20px;
}
.switch_item .right .stitle {
  color: #ffffff;
  font-size: 20px;
  display: block;
}
.switch_item .right .sstitle {
  color: #ffffff;
  font-size: 16px;
  display: block;
  padding-top: 0px;
}
div,
ul,
li,
span,
p {
  font-family: '微软雅黑';
}
body,
html {
  height: 100%;
}
.adv_home {
  width: 100%;
  text-align: center;
  /* min-height: 650px; */
  height: 650px;
  /* max-height: 721px; */
  clear: both;
  background-position: center;
  z-index: -10px;
  background: #50cdc5;
}
.adv_home ul {
  padding: 0;
  margin: 0;
}
#top {
  width: 100%;
  text-align: center;
}
.focus {
  height: 100%;
  /* overflow: hidden; */
  /* position: relative; */
}
/* focus_s */
.focus_s {
  background: rgba(255, 255, 255, 0);
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.focus_s ul {
  height: 100px;
  overflow: hidden;
  width: 100%;
  z-index: 9;
  width: 100%;
  background: rgba(255, 255, 255, 0.07);
  position: absolute;
}
.focus_s li {
  display: inline-block;
  cursor: pointer;
  height: 100px;
  width: 299px;
  overflow: hidden;
  margin-right: 0;
  background: rgba(255, 255, 255, 0);
}
.focus_s li.active {
  background: rgba(255, 255, 255, 0.09);
}

/*banner*/

.jypx_main_box {
  margin: 0 auto;
  width: 1200px;
}
.baner_left {
  width: 610px;
  color: #fff;
  text-align: left;
  margin-left: 20px;
  margin-right: 15px;
  margin-top: 40px;
  float: left;
}
.bal01 {
  font-size: 60px;
  font-weight: 600;
  text-shadow: 0 0px 0px rgba(0, 0, 0, 0.15);
}
.bal02 {
  font-size: 32px;
  font-weight: 500;
  line-height: 140%;
  padding-top: 5px;
}
.bal01 .qq {
  font-size: 68px;
  color: #ffef41;
}
.bal03 {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
}
.bal03 img {
  vertical-align: bottom;
  margin-right: 10px;
  margin-left: -10px;
}
.baner_right {
  float: right;
}
/*banner*/
/* focus_m */
.focus_m {
  height: 100%;
}
.focus_m ul {
  opacity: 1;
  filter: alpha(opacity=100);
  background: #50cdc5;
  height: 100%;
}

.focus_m li {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}
.focus_m li .icon {
  height: 15%; /*calc(100% - 427px)*/
}
.focus_mlibg01 {
  background: #20b5ff;
  background-position: center center;
}

.focus_mlibg02 {
  background: #5e5afa;
  background-position: center center;
}

.focus_mlibg03 {
  background: #fa5a8b;
  background-position: center center;
}

.focus_mlibg04 {
  background: #f4bb41;
  background-position: center center;
}

/* focus_l focus_r */
.focus_l,
.focus_r {
  position: absolute;
  display: block;
  width: 50px;
  height: 75px;
  overflow: hidden;
  z-index: 2;
  top: 150px;
}

.focus_l {
  left: 0;
}

.focus_r {
  right: 0;
}

.focus_l b,
.focus_r b {
  position: absolute;
  z-index: 1;
  display: block;
  width: 50px;
  height: 75px;
  overflow: hidden;
  background: #000;
  opacity: 0.3;
  filter: alpha(opacity=30);
  cursor: pointer;
}

.focus_l span,
.focus_r span {
  display: inline-block;
  position: relative;
  z-index: 2;
  width: 40px;
  height: 40px;
  top: 21px;
  cursor: pointer;
  margin-right: 28px;
}

.focus_l span {
  left: 5px;
  background-image: url(@/images/images/l.png);
  _background: none;
  _filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='@/images/images/l.png');
}

.focus_r span {
  left: 7px;
  background: url(@/images/images/r.png) 0 0 no-repeat;
  _background: none;
  _filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='@/images/images/r.png');
}

.focus_l:hover b,
.focus_r:hover b {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
a.homemfsybut:link {
  color: #fff;
}
a.homemfsybut:active {
  color: #ffffff;
}
a.homemfsybut:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0);
}
a.homemfsybut:visited {
  color: #ffffff;
}
.homemfsybut {
  width: 165px;
  height: 51px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  display: block;
  color: #ffffff;
  line-height: 51px;
  font-size: 20px;
  text-align: center;
}

.homemfsybut:hover {
  color: #666666;
  background: #ffffff;
}
a:link {
  text-decoration: none;
  color: #464646;
}
</style>
